<template>
  <div class="index-box">
    <div class="bg-font">
      <!-- <span>HUIYI&nbsp;&nbsp;&nbsp;JISUANJI</span>
            <h3>深圳市慧意计算机有限责任公司</h3> -->
      <img src="../assets/images/gs-font.png" />
      <div class="font-gt">
        <p v-if="language=='zh-CN'">
          深圳市慧意计算机有限责任公司是一家成立于2024年的互联网软件研发公司，总部位于深圳，目前有团队成员20人。慧意计算机作为科技创新新星，汇聚了来自腾讯、阿里、网易、百度、商汤科技及平安集团等国内外顶尖互联网与金融保险行业的精英人才，成功完成种子轮融资。我们深耕大数据与AI，提供智能化、定制化解决方案，精准洞察市场，优化流程，提升决策效率。团队以用户为核心，创新驱动，构建高效研发体系，快速响应市场。与高校及研究机构紧密合作，推动技术前沿和业务创新。秉持“慧聚数据，意领未来”理念，慧意计算机将持续拓展业务，创造价值，推动社会进步。我们诚邀各界伙伴，共谋发展，携手开创智慧未来新篇章，让技术与创新引领时代潮流。
          <!-- <span class="nospan"></span> -->
        </p>
        <p v-else style="letter-spacing: 0rem;font-size:2rem">Shenzhen Hui Yi Computer Co., Ltd. is an internet software development company established in 2024, headquartered in Shenzhen, with a current team of 20 members. As a rising star in technological innovation, Hui Yi Computer has gathered elite talents from top domestic and international internet and financial insurance industries, including Tencent, Alibaba, NetEase, Baidu, SenseTime, and Ping An Group, and has successfully completed its seed round financing.We specialize in big data and AI, providing intelligent and customized solutions that precisely insight into the market, optimize processes, and enhance decision-making efficiency. With users at the core and innovation as the driving force, our team has built an efficient research and development system to quickly respond to market needs. We closely collaborate with universities and research institutions to push forward technological frontiers and business innovations.Adhering to the philosophy of "Gathering Wisdom with Data, Leading the Future with Insight," Hui Yi Computer will continue to expand its business, create value, and promote social progress. We sincerely invite partners from all walks of life to seek common development, and together, we will open a new chapter of a smart future, letting technology and innovation lead the trend of the times.</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {

  },

  methods: {},
};
</script>

<style scoped>
.index-box {
  background-image: url("../assets/images/bg@2x.png");
  background-color: #5b8cad;
  height: 80rem;
  margin-top: 10.5rem;
  position: relative;
  background-size: cover;
  /* 背景图片覆盖整个容器 */
  background-position: center;
  background-repeat: no-repeat;
}

.bg-font {
  width: 120rem;
  height: 80rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}

.bg-font h3 {
  padding-top: 5rem;
  font-size: 6rem;
  letter-spacing: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}

.bg-font p {
}

.font-gt {
  width: 100%;
  background: url("../assets/images/bg-auto.png") no-repeat;
  background-size: 100% 100%;
  padding: 4rem;
  box-sizing: border-box;
  margin-top: 4rem;
}
.font-gt p {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 3rem;
  text-align: justify;
  border-radius: 2rem;
  letter-spacing: 0.4rem;
}

.bg-font .nospan {
  display: inline-block;
  width: 100%;
}


.bg-font a {
  display: block;
  width: 20rem;
  background: #0486ff;
  border-radius: 0.8rem;
  color: #fff;
  text-align: center;
  margin-top: 3rem;
  padding:1.5rem 0px;
}

.bg-font span {
  letter-spacing: 2.8rem;
  font-weight: 100;
  font-size: 2.4rem;
  color: rgba(255, 255, 255, 0.7);
}

.bg-font img {
  width: 103rem;
}
</style>